import React, { Dispatch, SetStateAction, useState } from "react";
import Router from "next/router";

import {
  APP_BUILD_INFO,
  IS_UNDER_PRODUCTION,
} from "@sellernote/_shared/src/constants";
import { useBodyScrollLock } from "@sellernote/_shared/src/hooks/common/useBodyScrollLock";
import { UserInfo } from "@sellernote/_shared/src/types/common/user";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import { getLatestBidType } from "@sellernote/_shared/src/utils/forwarding/bid";
import Button from "@sellernote/_sds-v2/src/components/button/Button";
import MenuIcon from "@sellernote/_sds-v2/src/components/svgIcons/MenuIcon";
import UserFilledIcon from "@sellernote/_sds-v2/src/components/svgIcons/UserFilledIcon";
import XMarkDefaultIcon from "@sellernote/_sds-v2/src/components/svgIcons/XMarkDefaultIcon";
import { COLOR } from "@sellernote/_sds-v2/src/styles/colors";

import { useTranslation } from "../../../../utils/i18n";

import Logo from "../../Logo";
import Logout from "../../Logout";
import { Menu } from "../../useMenuList";
import User from "../../User";
import MenuItem from "./MenuItem";
import Styled from "./index.styles";

export default function MobileHeader({
  menuList,
  loggedIn,
  userInfo,
  setIsVisibleLoginModal,
}: {
  menuList: Menu[];
  loggedIn: boolean;
  userInfo?: UserInfo;
  setIsVisibleLoginModal: Dispatch<SetStateAction<boolean>>;
}) {
  const { t } = useTranslation(["common", "common-new"]);

  const [isVisibleDrawer, setIsVisibleDrawer] = useState(false);
  const [activeMenuIndex, setActiveMenuIndex] = useState(
    undefined as unknown as number
  );

  useBodyScrollLock({
    enablesLocking: isVisibleDrawer,
  });

  const handleAuthClick = () => {
    if (!loggedIn) return setIsVisibleLoginModal(true);

    if (getLatestBidType() === "export") {
      Router.push("/mypage/export");
      return;
    }

    Router.push("/mypage");
  };

  return (
    <>
      <Styled.mobileHeader>
        <Logo type="header" />

        <Styled.authAndMenuContainer loggedIn={loggedIn}>
          <div className="auth-container">
            {loggedIn ? (
              <User />
            ) : (
              <UserFilledIcon
                width={24}
                height={24}
                color={loggedIn ? COLOR.grayScale_900 : COLOR.grayScale_700}
                onClick={handleAuthClick}
              />
            )}
          </div>

          <MenuIcon
            className="menu-icon"
            width={24}
            height={24}
            color={COLOR.grayScale_700}
            onClick={() => setIsVisibleDrawer(true)}
          />
        </Styled.authAndMenuContainer>
      </Styled.mobileHeader>

      {isVisibleDrawer && (
        <Styled.mobileDrawerContainer onClick={() => setIsVisibleDrawer(false)}>
          {!IS_UNDER_PRODUCTION && APP_BUILD_INFO && (
            <div className="build-info">
              {toFormattedDate(APP_BUILD_INFO.builtAt, "YYYY.MM.DD HH:mm:ss Z")}
            </div>
          )}

          <Styled.mobileAuthAndMenuContainer
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {!loggedIn && (
              <div className="auth-container">
                <Button
                  label={t("common-new:헤더_회원가입_버튼")}
                  theme="tertiary"
                  borderType="filled"
                  size="normal"
                  width="50%"
                  handleClick={() => {
                    Router.push("/register");
                  }}
                />

                <Button
                  label={t("common-new:헤더_로그인_버튼")}
                  size="normal"
                  theme="secondary"
                  borderType="filled"
                  width="50%"
                  handleClick={() => setIsVisibleLoginModal(true)}
                />
              </div>
            )}
            <nav className="menu-container">
              <ul>
                {menuList.map(({ label, submenuList, path }, i) => {
                  return (
                    <MenuItem
                      key={i}
                      label={label}
                      submenuList={submenuList}
                      path={path}
                      selected={activeMenuIndex === i}
                      onSelect={() => {
                        setActiveMenuIndex(i);
                      }}
                      closeDrawer={() => setIsVisibleDrawer(false)}
                    />
                  );
                })}
              </ul>
            </nav>

            {loggedIn && (
              <Logout buttonInfo={{ size: "normal", width: "100%" }} />
            )}

            <XMarkDefaultIcon
              width={32}
              height={32}
              color={COLOR.grayScale_700}
              onClick={() => setIsVisibleDrawer(false)}
              className="x-mark-default-icon"
            />
          </Styled.mobileAuthAndMenuContainer>
        </Styled.mobileDrawerContainer>
      )}
    </>
  );
}
