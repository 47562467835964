import React from "react";
import Link from "next/link";

import { Submenu } from "../../useMenuList";

export default function MobileMenuItem({
  submenu,
  isSelectedSubmenu,
}: {
  submenu: Submenu;
  isSelectedSubmenu: boolean;
}) {
  if (!isSelectedSubmenu) return null;

  return (
    <li className="sub-menu-item">
      <Link href={submenu.path}>
        <a>{submenu.labelForFooter}</a>
      </Link>
    </li>
  );
}
