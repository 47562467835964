import React, { useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";

import { Submenu } from "../../useMenuList";
import Styled from "./index.styles";

export default function MenuItem({
  label,
  path,
  submenuList,
}: {
  label: string;
  path?: string;
  submenuList?: Submenu[];
}) {
  const router = useRouter();

  const [isActive, setIsActive] = useState(false);

  return (
    <Styled.desktopMenu
      onMouseEnter={() => {
        setIsActive(true);
      }}
      onMouseLeave={() => {
        setIsActive(false);
      }}
      className="desktop-header-menu-item"
    >
      {path ? (
        <Link href={path}>
          <a className={`${isActive ? "active" : ""} label`}>{label}</a>
        </Link>
      ) : (
        <div className={`${isActive ? "active" : ""} label`}>{label}</div>
      )}

      {submenuList && (
        <>
          <Styled.submenuBackground
            className={`${isActive ? "active" : ""} background`}
          />

          <Styled.submenuList className={`${isActive ? "active" : ""}`}>
            {submenuList.map((v, i) => {
              return (
                <Styled.desktopSubmenu
                  key={i}
                  onClick={(e) => {
                    // 아래 a link와 중복기능이지만, subLabel과 뱃지를 클릭했을때도 이동해야 하므로 추가함
                    e.stopPropagation();
                    setIsActive(false);

                    // company#about 처럼 hash 를 router.push 하는 경우에 Cancel Rendering Route Error 가 있어서, link 이동을 완료한 이후에 hash로 이동하도록 처리.
                    router.push(v.path).then(() => {
                      if (!v.pathHash) return;

                      router.push(v.pathHash);
                    });
                  }}
                >
                  <div className="label-container">
                    <Link href={v.path}>
                      <a>
                        <span className="label">{v.label}</span>
                      </a>
                    </Link>

                    <div className="badge-container">
                      {v.badges?.map((badge, i) => (
                        <div className="badge" key={i}>
                          {badge}
                        </div>
                      ))}
                    </div>
                  </div>

                  <span className="sub-label">{v.subLabel}</span>
                </Styled.desktopSubmenu>
              );
            })}
          </Styled.submenuList>
        </>
      )}
    </Styled.desktopMenu>
  );
}
