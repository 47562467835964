import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useSetAtom } from "jotai";
import Router from "next/router";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import Icon from "@sellernote/_shared/src/componentsToMoveToV1/Icon";
import { APP_REGION } from "@sellernote/_shared/src/constants";
import AUTH_QUERY from "@sellernote/_shared/src/queries/forwarding/AUTH_QUERY";
import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { ResponseFailureInfo } from "@sellernote/_shared/src/types/common/common";
import { handleLoginSubmitByEnter } from "@sellernote/_shared/src/utils/common/auth";
import { useCheckIsMobile } from "@sellernote/_shared/src/utils/common/hook";
import { makeUserLoginFailedMessage } from "@sellernote/_shared/src/utils/forwarding/auth";
import TextButton from "@sellernote/_sds-v1/src/components/button/TextButton";
import InputCheckBox from "@sellernote/_sds-v1/src/components/input/InputCheckBox";
import InputText from "@sellernote/_sds-v1/src/components/input/InputText";

import { setNeedToKeepState } from "../../utils/etc";
import { Trans, useTranslation } from "../../utils/i18n";

import { MARKETING_ATOMS } from "../../jotaiStates/common/marketing";
import Styled from "./index.styles";

type LoginInputKey = "email" | "password";

export default function LoginModalForm({
  backToAfterRegister,
  className,
  isClickedLoginButton,
  setIsVisibleFindPassword,
  isPromotion,
  onSuccessOfLogin,
}: {
  backToAfterRegister?: string;
  className?: string;
  isClickedLoginButton?: boolean;
  setIsVisibleFindPassword: Dispatch<SetStateAction<boolean>>;
  isPromotion?: boolean;
  onSuccessOfLogin?: () => void;
}) {
  const { isMobile } = useCheckIsMobile();

  const { t } = useTranslation(["auth-new", "common"]);

  const [failureInfo, setFailureInfo] = useState<ResponseFailureInfo>();

  const {
    mutate: login,
    isError: isLoginError,
    ResponseHandler: ResponseHandlerOfLogin,
  } = AUTH_QUERY.useUserLogin({
    onSuccess: onSuccessOfLogin,
    onError: (failureInfo) => {
      setFailureInfo(failureInfo);
    },
  });

  const setNeedToSendGtmLoginEvent = useSetAtom(
    MARKETING_ATOMS.NEED_TO_SEND_GTM_LOGIN_EVENT
  );

  const initialInputState = {
    email: "",
    password: "",
  };

  const [inputState, setInputState] = useState(initialInputState);

  const { email, password } = inputState;

  const [autoLogin, setAutoLogin] = useState(true);

  const errorMsg = useMemo(() => {
    if (!failureInfo) return null;

    return makeUserLoginFailedMessage({ failureInfo });
  }, [failureInfo]);

  function updateInput(key: LoginInputKey, value: string) {
    setInputState({ ...inputState, [key]: value });

    if (failureInfo) {
      setFailureInfo(undefined);
    }
  }

  const handleLoginSubmit = useCallback(() => {
    // 로그인 이벤트를 보내기 위해 flag셋팅
    setNeedToSendGtmLoginEvent(true);
    login({ accountId: email, password, _autoLogin: autoLogin });
  }, [setNeedToSendGtmLoginEvent, login, email, password, autoLogin]);

  const isQuotationInProgress =
    backToAfterRegister === "forwarding/ocean/order" ||
    backToAfterRegister === "forwarding/air/order" ||
    backToAfterRegister === "oceanticket/order" ||
    backToAfterRegister === "consolidation/order" ||
    backToAfterRegister === "assistance/order";

  return (
    <>
      <Styled.container
        className={`${className ? className : ""} login-in-progress`}
      >
        <Styled.formContainer
          onKeyPress={(e) =>
            handleLoginSubmitByEnter({ e, onLoginSubmit: handleLoginSubmit })
          }
        >
          <h2>
            {isClickedLoginButton
              ? t("auth-new:공통_로그인_타이틀")
              : isPromotion
              ? t("auth-new:로그인_프로모션_이용_안내")
              : t("auth-new:로그인_이용_필수_안내")}
          </h2>

          {isQuotationInProgress && (
            <Styled.quotationInProgressContainer>
              <Icon
                type="circleFilledError"
                color={isMobile ? COLOR.secondaryDeepBlue : COLOR.grayScale_700}
                size={1}
              />

              <span className="quotation-in-progress-guide">
                {t("auth-new:로그인_회원가입_입력_유지_안내")}
              </span>
            </Styled.quotationInProgressContainer>
          )}

          <InputText
            name="email"
            label={t("auth-new:공통_로그인_이메일")}
            value={email}
            placeholder={t("auth-new:공통_입력")}
            setValue={(v) => updateInput("email", v as string)}
            borderType="filled"
            valueType="string"
            className="input-email"
            disabledToFocus={isLoginError}
          />

          <InputText
            name="password"
            label={t("common:password")}
            value={password}
            placeholder={t("auth-new:공통_입력")}
            setValue={(v) => updateInput("password", v as string)}
            borderType="filled"
            valueType="password"
            disabledToFocus={isLoginError}
          />

          {errorMsg && <Styled.errorMsg>{errorMsg}</Styled.errorMsg>}

          <Styled.loginOptionContainer>
            <InputCheckBox
              label={t("auth-new:로그인_자동로그인")}
              shape="rect"
              checked={autoLogin}
              handleCheck={() => setAutoLogin(!autoLogin)}
            />

            <TextButton
              theme="default"
              size="12px"
              label={t("auth-new:공통_로그인_비밀번호찾기")}
              handleClick={() => setIsVisibleFindPassword(true)}
            />
          </Styled.loginOptionContainer>

          <Button
            theme="primary"
            size="normal"
            label={t("common:login")}
            handleClick={handleLoginSubmit}
            disabled={!(email && password)}
          />

          <Styled.registerContainer>
            <span className="register-guide">
              {t("auth-new:로그인_회원가입_안내")}
            </span>

            <TextButton
              theme="withIcon"
              label={t("auth-new:로그인_회원가입_버튼")}
              size="14px"
              handleClick={() => {
                setNeedToKeepState("yes");
                Router.push({
                  pathname: "/register",
                  query: {
                    backTo: backToAfterRegister || "",
                    isPromotion: isPromotion,
                  },
                });
              }}
              icon="arrowRight"
            />
          </Styled.registerContainer>
        </Styled.formContainer>

        <Styled.imageContainer>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            src="/assets/images/login/login-modal.png"
            className="illust-login"
            alt="illust-login"
          />
          {APP_REGION === "KR" ? (
            // eslint-disable-next-line @next/next/no-img-element
            <img
              src="/assets/images/layout/logo.webp"
              className="logo"
              alt="logo"
            />
          ) : (
            // eslint-disable-next-line @next/next/no-img-element
            <img
              src="/assets/images/sg/logo-sg.png"
              className="logo"
              alt="logo"
            />
          )}

          {/** TODO: SG쉽다 배포 + auth-new.json 수정사항이 반영되면 분기처리 삭제 */}
          <span className="shipda-desc">
            {APP_REGION === "KR" ? (
              <Trans i18nKey="auth-new:공통_로그인_서비스소개" />
            ) : (
              <>
                Bringoodz is a digital logistics forwarding service
                <br />
                We move the freight ourselves.
              </>
            )}
          </span>
        </Styled.imageContainer>
      </Styled.container>

      {ResponseHandlerOfLogin}
    </>
  );
}
