import { useMemo } from "react";
import Router from "next/router";

import { APP_REGION } from "@sellernote/_shared/src/constants";
import { AccountInfo } from "@sellernote/_shared/src/types/common/team";
import { useCheckIsMobile } from "@sellernote/_shared/src/utils/common/hook";
import { omitWithEllipsis } from "@sellernote/_shared/src/utils/common/string";
import { getLatestBidType } from "@sellernote/_shared/src/utils/forwarding/bid";
import TextButton from "@sellernote/_sds-v2/src/components/button/TextButton";

import { useTranslation } from "../../../utils/i18n";

import Styled from "./index.styles";

export default function CurrentAccountInfo({
  currentAccountInfo,
  isMyPage,
  isTransparentBackground,
}: {
  currentAccountInfo: AccountInfo;
  isMyPage?: boolean;
  isTransparentBackground?: boolean;
}) {
  const { isMobile } = useCheckIsMobile();

  const { t } = useTranslation(["common-new"]);

  const privateOrTeamInfo = currentAccountInfo.isPrivate
    ? t("common-new:마이페이지_헤더_유형_개인")
    : t("common-new:마이페이지_헤더_유형_팀");

  const teamInfo =
    omitWithEllipsis({
      src: currentAccountInfo?.name ?? "",
      maxLength: isMobile ? 3 : 10,
      ellipsis: "...",
    }) ?? "-";

  const companyInfo =
    omitWithEllipsis({
      src: currentAccountInfo?.company ?? "",
      maxLength: isMobile ? 3 : APP_REGION === "KR" ? 10 : 15,
      ellipsis: "...",
    }) ?? "-";

  /**
   * SG쉽다에서는 '회사명' 문구를 노출시키지 않음
   */
  const accountInfo = useMemo(() => {
    if (currentAccountInfo.isPrivate) {
      return isMobile || APP_REGION === "SG"
        ? `${privateOrTeamInfo} | ${companyInfo}`
        : `${privateOrTeamInfo} | ${t(
            "common-new:마이페이지_헤더_로그인정보_회사명"
          )}: ${companyInfo}`;
    }

    if (isMobile) return `${privateOrTeamInfo} | ${teamInfo} | ${companyInfo}`;

    return APP_REGION === "KR"
      ? `${privateOrTeamInfo} | ${t(
          "common-new:마이페이지_헤더_로그인정보_팀명"
        )}: ${teamInfo} | ${t(
          "common-new:마이페이지_헤더_로그인정보_회사명"
        )}: ${companyInfo}`
      : `${privateOrTeamInfo} | ${t(
          "common-new:마이페이지_헤더_로그인정보_팀명"
        )}: ${teamInfo} | ${companyInfo}`;
  }, [
    companyInfo,
    currentAccountInfo.isPrivate,
    isMobile,
    privateOrTeamInfo,
    t,
    teamInfo,
  ]);

  return (
    <Styled.currentAccountInfo>
      {/* Mypage 에서는 Desktop 의 경우만 표시 */}
      {isMyPage ? (
        !isMobile && (
          <Styled.myPageCurrentAccountInfo>
            {accountInfo}
          </Styled.myPageCurrentAccountInfo>
        )
      ) : (
        <TextButton
          theme={isTransparentBackground ? "white" : "primary"}
          fontSize={14}
          label={accountInfo}
          handleClick={() => {
            if (getLatestBidType() === "export") {
              Router.push("/mypage/export");
              return;
            }

            Router.push("/mypage");
          }}
          hasUnderline={true}
        />
      )}
    </Styled.currentAccountInfo>
  );
}
