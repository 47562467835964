/* eslint-disable @next/next/no-img-element */
import { useEffect } from "react";
import { useAtom } from "jotai";
import Image from "next/image";
import { useRouter } from "next/router";

import { APP_REGION } from "@sellernote/_shared/src/constants";
import { CHANNEL_IO_CUSTOM_LAUNCHER_SELECTOR_CLASS_NAME } from "@sellernote/_shared/src/services/chat/channelIO";
import { CHAT_BUTTON_DEFAULT_BOTTOM_POSITION } from "@sellernote/_shared/src/services/chat/constants";
import initChatButtonUI from "@sellernote/_shared/src/services/chat/initChatButtonUI";
import openChatModal from "@sellernote/_shared/src/services/chat/openChatModal";
import { useCheckIsMobile } from "@sellernote/_shared/src/utils/common/hook";

import useCheckNeedToOpenChatModal from "./hooks/useCheckNeedToOpenChatModal";

import { COMMON_LAYOUT_ATOMS } from "../../jotaiStates/common/layout";
import Styled from "./index.styles";

export default function FloatingChatButton() {
  const [bottomPosition, setBottomPosition] = useAtom(
    COMMON_LAYOUT_ATOMS.FLOATING_CHAT_BUTTON_BOTTOM_POSITION
  );

  const [unreadCount, setUnreadCount] = useAtom(
    COMMON_LAYOUT_ATOMS.CHAT_BUTTON_UNREAD_COUNT
  );

  const { isMobile } = useCheckIsMobile();

  useEffect(() => {
    setBottomPosition(
      CHAT_BUTTON_DEFAULT_BOTTOM_POSITION[isMobile ? "mobile" : "desktop"]
    );
  }, [isMobile, setBottomPosition]);

  initChatButtonUI(setUnreadCount);

  useCheckNeedToOpenChatModal();

  const { asPath } = useRouter();
  const isOnMyPage = asPath.includes("/mypage");
  // 데크스탑 마이페이지에서는 헤더에서 채널톡 아이콘을 표시함
  if (isOnMyPage && !isMobile) return null;

  return (
    <Styled.chatButton
      className={CHANNEL_IO_CUSTOM_LAUNCHER_SELECTOR_CLASS_NAME}
      bottomPosition={bottomPosition}
      onClick={openChatModal}
    >
      {typeof unreadCount === "number" && unreadCount > 0 && (
        <div className="unread-count">{unreadCount}</div>
      )}

      {isMobile ? (
        <>
          {
            // Next.js Image 컴포넌트 사용시 Width와 Height을 오버라이딩하여 버그 발생, 일단 html 태그로 대체
          }
          <img
            src="/assets/images/chat_button_mobile.webp"
            alt="logo"
            width={42}
            height={42}
          />
        </>
      ) : (
        <>
          {APP_REGION === "KR" && (
            // webp나 png를 사용했을때 화질열화가 있어서 예외적으로 svg사용
            <Image
              src={"/assets/images/chat_button_shipda.svg"}
              alt="logo"
              width={173}
              height={50}
            />
          )}

          {APP_REGION === "SG" && (
            <Image
              // webp나 png를 사용했을때 화질열화가 있어서 예외적으로 svg사용
              src={"/assets/images/chat_button_bringoodz.svg"}
              alt="logo"
              width={152}
              height={50}
            />
          )}
        </>
      )}
    </Styled.chatButton>
  );
}
