import React, { Dispatch, SetStateAction } from "react";
import Router from "next/router";

import {
  APP_BUILD_INFO,
  APP_REGION,
  IS_UNDER_PRODUCTION,
  SERVICE_INTRODUCTION_INQUIRY_URL,
} from "@sellernote/_shared/src/constants";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import LinkButton from "@sellernote/_sds-v2/src/components/button/Button/LinkButton";
import TextButton from "@sellernote/_sds-v2/src/components/button/TextButton";

import { useTranslation } from "../../../../utils/i18n";

import Logo from "../../Logo";
import { Menu } from "../../useMenuList";
import User from "../../User";
import MenuItem from "./MenuItem";
import { SG_CUSTOMIZED_HEADER_MENU_LIST } from "./SG_HEADER_MENU_LIST";
import Styled from "./index.styles";

export default function DesktopHeader({
  menuList,
  loggedIn,
  setIsVisibleLoginModal,
  isTransparentBackground,
}: {
  menuList: Menu[];
  loggedIn: boolean;
  setIsVisibleLoginModal: Dispatch<SetStateAction<boolean>>;
  isTransparentBackground?: boolean;
}) {
  const { t } = useTranslation(["common-new"]);

  return (
    <Styled.desktopHeader>
      {!IS_UNDER_PRODUCTION && APP_BUILD_INFO && (
        <div className="build-info">
          {toFormattedDate(APP_BUILD_INFO.builtAt, "YYYY.MM.DD HH:mm:ss Z")}
        </div>
      )}

      <Styled.leftContainer>
        <Logo type="header" />

        <nav>
          {APP_REGION === "SG" && (
            <Styled.menuContainer>
              {SG_CUSTOMIZED_HEADER_MENU_LIST.map(({ label, path }, i) => {
                return <MenuItem key={i} label={label} path={path} />;
              })}
            </Styled.menuContainer>
          )}

          {APP_REGION === "KR" && (
            <Styled.menuContainer>
              {menuList.map(({ label, submenuList, path }, i) => {
                return (
                  <MenuItem
                    key={i}
                    label={label}
                    submenuList={submenuList}
                    path={path}
                  />
                );
              })}
            </Styled.menuContainer>
          )}
        </nav>
      </Styled.leftContainer>

      <Styled.authContainer>
        {loggedIn ? (
          <User isTransparentBackground={isTransparentBackground} />
        ) : (
          <>
            <TextButton
              label={t("common-new:헤더_회원가입_버튼")}
              theme="gray"
              fontSize={14}
              handleClick={() => {
                Router.push("/register");
              }}
              className="custom-header-register-text-button"
            />

            <TextButton
              label={t("common-new:헤더_로그인_버튼")}
              theme="gray"
              fontSize={14}
              handleClick={() => setIsVisibleLoginModal(true)}
              className="custom-header-register-text-button"
            />
          </>
        )}

        <LinkButton
          label={t("common-new:헤더_도입문의_버튼")}
          theme="primary"
          borderType="filled"
          size="small"
          href={SERVICE_INTRODUCTION_INQUIRY_URL}
        />
      </Styled.authContainer>
    </Styled.desktopHeader>
  );
}
