import styled, { css } from "styled-components";

import { mobile } from "@sellernote/_shared/src/styles/functions";
import { COLOR, TEXT_COLOR } from "@sellernote/_sds-v2/src/styles/colors";
import { setFontStyle } from "@sellernote/_sds-v2/src/styles/typography";

const desktopHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  min-width: 1280px;

  ${mobile(css`
    display: none;
  `)}

  > .build-info {
    color: gray;
    position: absolute;
    top: 0;
    right: 2px;
    font-size: 12px;
  }
`;

const leftContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 16px 0;
`;

const menuContainer = styled.ul`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 80px;
`;

const desktopMenu = styled.li`
  position: relative;
  margin-right: 24px;

  > .label {
    ${setFontStyle("SubHead2")}
    color: ${TEXT_COLOR.black_2};
    display: flex;
    align-items: center;
    word-break: keep-all;
    padding: 8px 4px;
    cursor: pointer;

    a,
    a:visited {
      color: ${TEXT_COLOR.black_2};
    }

    &.active,
    &.active a {
      color: ${COLOR.primary_600};
    }
  }
`;

const submenuList = styled.ul`
  display: none;

  &.active {
    display: block;
    position: absolute;
    padding-top: 41px;
    left: 0;
    width: 400px;
    z-index: 102;
  }
`;

const submenuBackground = styled.div`
  height: 0;

  &.active {
    position: absolute;
    background-color: white;
    height: 320px;
    width: 300vw;
    left: -1600px;
    z-index: 101;
    top: 54px;
    // 디자인 상 header 가 submenu 를 덮는 형태인데,
    // submenu 가 구조상 header 의 child 라 z-index 로는 구현이 불가능해서
    // header 가 submenu 를 덮는 것 처럼 보이도록 box-shadow를 elevation과는 별도로 작성.
    box-shadow: 0px 6px 10px -6px rgba(0, 0, 0, 0.45),
      inset 0px 6px 10px -6px rgba(0, 0, 0, 0.35);
  }
`;

const desktopSubmenu = styled.li`
  margin-bottom: 17px;
  color: ${TEXT_COLOR.black_2};
  z-index: 2;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  a,
  a:visited {
    color: ${TEXT_COLOR.black_2};
  }

  &:hover,
  &:hover > .label-container > a,
  &:hover > .sub-label {
    color: ${COLOR.primary_600};
  }

  .label-container {
    display: flex;
    align-items: center;
  }

  .label {
    ${setFontStyle("SubHead2")}
  }

  .sub-label {
    display: block;
    padding: 0 10px;
    margin-top: 4px;
    ${setFontStyle("Body3")}
    color: ${TEXT_COLOR.black_3};
  }

  .badge-container {
    display: flex;

    > .badge {
      margin-left: 4px;
      padding: 3px 8px;
      color: ${TEXT_COLOR.white_1};
      background-color: ${COLOR.grayScale_700};
      border-radius: 4px;
      ${setFontStyle("Body4")}
    }
  }
`;

const authContainer = styled.div`
  display: flex;
  align-items: center;

  > div:not(:last-child),
  > button:not(:last-child) {
    margin-right: 24px;
  }
`;

export default {
  desktopHeader,
  leftContainer,
  menuContainer,
  authContainer,
  desktopMenu,
  submenuBackground,
  submenuList,
  desktopSubmenu,
};
