import { useEffect } from "react";
import { useAtom } from "jotai";
import { useRecoilValue } from "recoil";

import USER_QUERY from "@sellernote/_shared/src/queries/forwarding/USER_QUERY";
import { FORWARDING_AUTH_ATOMS } from "@sellernote/_shared/src/states/forwarding/auth";
import {
  addEventToGTMDataLayer,
  setUserInfoToGTMDataLayer,
} from "@sellernote/_shared/src/utils/common/gtm";

import { MARKETING_ATOMS } from "../../jotaiStates/common/marketing";

/**
 * 로그인한 유저 정보의 변동시 필요한 GTM관련 로직을 처리
 */
export default function useSetGTMByUserInfo() {
  const [needToSendGtmLoginEvent, setNeedToSendGtmLoginEvent] = useAtom(
    MARKETING_ATOMS.NEED_TO_SEND_GTM_LOGIN_EVENT
  );

  const loggedIn = useRecoilValue(FORWARDING_AUTH_ATOMS.USER_LOGGED_IN);

  const { data: userInfo } = USER_QUERY.useGetUserInfo({
    enabled: Boolean(loggedIn),
  });

  useEffect(() => {
    // user정보가 변하면 GTM dataLayer에 반영
    setUserInfoToGTMDataLayer(userInfo);

    if (userInfo && needToSendGtmLoginEvent) {
      // 유저정보를 dataLayer에 추가한 후(setUserInfoToGTMDataLayer 실행 후) login이벤트를 보낸다(순서 중요)
      addEventToGTMDataLayer({
        event: "login",
      });
      setNeedToSendGtmLoginEvent(false);
    }
  }, [needToSendGtmLoginEvent, setNeedToSendGtmLoginEvent, userInfo]);
}
