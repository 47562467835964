/**
 * SG쉽다 커스텀 메뉴
 * 하위 메뉴를 상위 메뉴로 끌어올리고, 바로 라우팅 가능하게 커스터마이징 (AB12611)
 * 추후 SG쉽다 메뉴가 늘어나면 원복 예정(AB12611)
 */
export const SG_CUSTOMIZED_HEADER_MENU_LIST = [
  { label: "Introduction", path: "/company" },
  {
    label: "Ocean Freight",
    path: "/forwarding/ocean/order?step=1",
  },
  {
    label: "Air Freight",
    path: "/forwarding/air/order?step=1",
  },
  {
    label: "Guide",
    path: "/support/guide",
  },
  {
    label: "Notice",
    path: "/support/notice",
  },
];
