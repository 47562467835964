import { useMemo } from "react";

import { APP_REGION } from "@sellernote/_shared/src/constants";
import { UserInfo } from "@sellernote/_shared/src/types/common/user";

import { Trans, useTranslation } from "../../utils/i18n";

type Menu = {
  label: string;
  path?: string;
  submenuList?: Submenu[];
};
type Submenu = {
  label: string;
  labelForFooter: string;
  subLabel: React.ReactNode;
  badges?: string[];
  path: string;
  pathHash?: string;
};

export type { Menu, Submenu };

/**
 * 메뉴 목록(header와 footer 공용으로 사용)을 반환
 */
export default function useMenuList({
  userInfo,
}: {
  userInfo: UserInfo | undefined;
}) {
  const { t } = useTranslation(["common", "common-new"]);

  const menuList = useMemo(() => {
    const hasBidAuthority = userInfo?.access.detail.transit === "write";

    const COMPANY_MENU: Menu = {
      label: t("common-new:소개"),
      submenuList: [
        {
          label: t("common-new:헤더_메뉴_소개_소분류_회사소개"),
          labelForFooter: t("common-new:푸터_메뉴_소개_소분류_회사소개"),
          subLabel: "About us",
          path: "/company",
        },
      ],
    };

    const INTEGRATED_REALTIME_SERVICE_FOR_OCEAN_SUB_MENU: Submenu = {
      label: t("common-new:헤더_메뉴_수입운송_소분류_해상운임조회"),
      badges: ["FCL", "LCL"],
      labelForFooter: t("common-new:푸터_메뉴_수입운송_소분류_해상운임조회"),
      subLabel: t("common-new:헤더_메뉴_수입운송_소분류_해상운임조회_설명"),
      path:
        APP_REGION === "KR" ? "/forwarding/ocean" : "/forwarding/ocean/order",
    };

    const INTEGRATED_REALTIME_SERVICE_FOR_AIR_SUB_MENU: Submenu = {
      label: t("common-new:헤더_메뉴_수입운송_소분류_항공운임조회"),
      badges: ["AIR"],
      labelForFooter: t("common-new:푸터_메뉴_수입운송_소분류_항공운임조회"),
      subLabel: t("common-new:헤더_메뉴_수입운송_소분류_항공운임조회_설명"),
      path: APP_REGION === "KR" ? "/forwarding/air" : "/forwarding/air/order",
    };

    const FORWARDING_SCHEDULE_SUB_MENU: Submenu = {
      label: "스케줄 조회",
      badges: ["ALL"],
      labelForFooter: "스케줄 조회",
      subLabel: "해운/항공사별 스케줄 조회",
      path: "/forwarding/schedule",
    };

    const OCEAN_TICKET_SUB_MENU: Submenu = {
      label: t("common-new:헤더_메뉴_특화운송_소분류_오션티켓"),
      badges: [t("common-new:헤더_메뉴_특화운송_소분류_오션티켓_국가태그")],
      labelForFooter: t("common-new:푸터_메뉴_특화운송_소분류_오션티켓"),
      subLabel: (
        <Trans i18nKey="common-new:헤더_메뉴_특화운송_소분류_오션티켓_설명" />
      ),
      path: "/oceanticket",
    };

    const IMPORT_TRADE_TUTORIAL_SUB_MENU: Submenu = {
      label: t("common-new:헤더_메뉴_고객지원_소분류_튜토리얼"),
      labelForFooter: t(
        "common-new:푸터_메뉴_프로모션_고객지원_소분류_수입무역 튜토리얼"
      ),
      subLabel: t("common-new:헤더_메뉴_고객지원_소분류_튜토리얼_설명"),
      path: "/assistance",
    };

    const FULFILLMENT_MENU: Menu = {
      label: t("common-new:헤더_메뉴_풀필먼트"),
      submenuList: [
        {
          label: `국내출고`,
          badges: ["B2C", "B2B"],
          labelForFooter: `국내출고`,
          subLabel: `스마트스토어, 쿠팡, 카페24 등 국내출고`,
          path: `/fulfillment/domestic`,
        },
        {
          label: `해외출고`,
          badges: [`B2C`, `B2B`],
          labelForFooter: `해외출고`,
          subLabel: `Shopify, Cafe24 Global 등 해외출고`,
          path: `/fulfillment/overseas`,
        },
        {
          label: `이벤트출고`,
          badges: [`B2C`, `B2B`],
          labelForFooter: `이벤트출고`,
          subLabel: `와디즈, 텀블벅, 홈쇼핑 등 단발성 대량출고`,
          path: `/fulfillment/event`,
        },
      ],
    };

    if (APP_REGION === "KR") {
      // 유저정보가 있고, 운송관리 권한이 없는 경우의 메뉴
      if (userInfo && !hasBidAuthority) {
        return [
          COMPANY_MENU,

          FULFILLMENT_MENU,
          {
            label: t("common-new:헤더_메뉴_프로모션"),
            submenuList: [
              {
                label: t("common-new:헤더_메뉴_프로모션_소분류_운송비할인보장"),
                badges: [
                  t("common-new:헤더_메뉴_프로모션_소분류_할인수단태그"),
                ],
                labelForFooter: t(
                  "common-new:푸터_메뉴_프로모션_소분류_운송비할인보장"
                ),
                subLabel: t(
                  "common-new:헤더_메뉴_프로모션_소분류_운송비할인보장_설명"
                ),
                path: "/promotion/container",
              },
            ],
          },
          {
            label: t("common-new:헤더_메뉴_고객지원"),
            submenuList: [
              {
                label: t("common-new:헤더_메뉴_고객지원_소분류_가이드"),
                labelForFooter: t(
                  "common-new:푸터_메뉴_프로모션_고객지원_소분류_가이드"
                ),
                subLabel: t("common-new:헤더_메뉴_고객지원_소분류_가이드_설명"),
                path: "/support/guide",
              },
              {
                label: t("common-new:헤더_메뉴_고객지원_소분류_공지사항"),
                labelForFooter: t(
                  "common-new:푸터_메뉴_프로모션_고객지원_소분류_공지사항"
                ),
                subLabel: t(
                  "common-new:헤더_메뉴_고객지원_소분류_공지사항_설명"
                ),
                path: "/support/notice",
              },
              {
                label: t("common-new:헤더_메뉴_고객지원_소분류_동영상"),
                labelForFooter: t(
                  "common-new:푸터_메뉴_프로모션_고객지원_소분류_동영상"
                ),
                subLabel: t("common-new:헤더_메뉴_고객지원_소분류_동영상_설명"),
                path: "/support/onlinelecture",
              },
            ],
          },
        ];
      }

      return [
        COMPANY_MENU,
        {
          label: t("common-new:헤더_메뉴_수입운송"),
          submenuList: [
            INTEGRATED_REALTIME_SERVICE_FOR_OCEAN_SUB_MENU,
            INTEGRATED_REALTIME_SERVICE_FOR_AIR_SUB_MENU,
            FORWARDING_SCHEDULE_SUB_MENU,
          ],
        },
        {
          label: t("common-new:헤더_메뉴_특화운송"),
          submenuList: [
            {
              label: `${t(
                "common-new:헤더_메뉴_특화운송_소분류_창고입고콘솔"
              )}(W/H Consol)`,
              badges: [
                t("common-new:헤더_메뉴_특화운송_소분류_창고입고콘솔_국가태그"),
                t(
                  "common-new:헤더_메뉴_특화운송_소분류_창고입고콘솔_운송방법태그"
                ),
              ],
              labelForFooter: t(
                "common-new:푸터_메뉴_특화운송_소분류_창고입고"
              ),
              subLabel: (
                <>
                  <Trans i18nKey="common-new:헤더_메뉴_특화운송_소분류_창고입고콘솔_설명" />
                </>
              ),
              path: "/consolidation",
            },
            OCEAN_TICKET_SUB_MENU,
          ],
        },
        FULFILLMENT_MENU,
        {
          label: t("common-new:헤더_메뉴_프로모션"),
          submenuList: [
            {
              label: t("common-new:헤더_메뉴_프로모션_소분류_운송비할인보장"),
              badges: [t("common-new:헤더_메뉴_프로모션_소분류_할인수단태그")],
              labelForFooter: t(
                "common-new:푸터_메뉴_프로모션_소분류_운송비할인보장"
              ),
              subLabel: t(
                "common-new:헤더_메뉴_프로모션_소분류_운송비할인보장_설명"
              ),
              path: "/promotion/container",
            },
          ],
        },
        {
          label: t("common-new:헤더_메뉴_고객지원"),
          submenuList: [
            {
              label: `${t(
                "common-new:헤더_메뉴_고객지원_소분류_가이드"
              )}(Guide)`,
              labelForFooter: t(
                "common-new:푸터_메뉴_프로모션_고객지원_소분류_가이드"
              ),
              subLabel: t("common-new:헤더_메뉴_고객지원_소분류_가이드_설명"),
              path: "/support/guide",
            },
            {
              label: t("common-new:헤더_메뉴_고객지원_소분류_공지사항"),
              labelForFooter: t(
                "common-new:푸터_메뉴_프로모션_고객지원_소분류_공지사항"
              ),
              subLabel: t("common-new:헤더_메뉴_고객지원_소분류_공지사항_설명"),
              path: "/support/notice",
            },
            {
              label: t("common-new:헤더_메뉴_고객지원_소분류_동영상"),
              labelForFooter: t(
                "common-new:푸터_메뉴_프로모션_고객지원_소분류_동영상"
              ),
              subLabel: t("common-new:헤더_메뉴_고객지원_소분류_동영상_설명"),
              path: "/support/onlinelecture",
            },
            IMPORT_TRADE_TUTORIAL_SUB_MENU,
          ],
        },
      ];
    }

    /**
     * SG쉽다에서 헤더 메뉴 리스트는 따로 커스터마이징이 필요하여 SG_CUSTOMIZED_HEADER_MENU 사용
     * 아래 배열은 푸터, 모바일 메뉴에서 사용됨
     * 추후 SG쉽다 메뉴가 늘어나면 원복 예정(AB12611)
     */
    if (APP_REGION === "SG") {
      return [
        {
          label: t("common-new:헤더_메뉴_소개"),
          submenuList: [
            {
              label: t("common-new:헤더_메뉴_소개"),
              labelForFooter: t("common-new:푸터_메뉴_소개"),
              subLabel: "",
              path: "/company",
            },
          ],
        },
        {
          label: t("common-new:헤더_메뉴_수입운송"),
          submenuList: [
            INTEGRATED_REALTIME_SERVICE_FOR_OCEAN_SUB_MENU,
            INTEGRATED_REALTIME_SERVICE_FOR_AIR_SUB_MENU,
          ],
        },
        {
          label: t("common-new:헤더_메뉴_고객지원"),
          submenuList: [
            {
              label: t("common-new:헤더_메뉴_고객지원_소분류_가이드"),
              labelForFooter: t(
                "common-new:푸터_메뉴_프로모션_고객지원_소분류_가이드"
              ),
              subLabel: t("common-new:헤더_메뉴_고객지원_소분류_가이드_설명"),
              path: "/support/guide",
            },
            {
              label: t("common-new:헤더_메뉴_고객지원_소분류_공지사항"),
              labelForFooter: t(
                "common-new:푸터_메뉴_프로모션_고객지원_소분류_공지사항"
              ),
              subLabel: t("common-new:헤더_메뉴_고객지원_소분류_공지사항_설명"),
              path: "/support/notice",
            },
          ],
        },
      ];
    }

    return [];
  }, [t, userInfo]);

  return menuList;
}
