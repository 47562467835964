import React from "react";
import { useQueryClient } from "react-query";
import Router from "next/router";

import { GET_ACCOUNT_INFO_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/team";
import Loading from "@sellernote/_shared/src/componentsToMoveToV1/Loading";
import TEAM_QUERY from "@sellernote/_shared/src/queries/forwarding/TEAM_QUERY";
import { USER_QUERY_KEY_GEN } from "@sellernote/_shared/src/queries/forwarding/USER_QUERY";
import {
  useCheckIsMobile,
  useClientSidePaging,
} from "@sellernote/_shared/src/utils/common/hook";
import { getLatestBidType } from "@sellernote/_shared/src/utils/forwarding/bid";
import Button from "@sellernote/_sds-v2/src/components/button/Button";
import Pagination from "@sellernote/_sds-v2/src/components/pagination/Pagination";
import SettingSolidIcon from "@sellernote/_sds-v2/src/components/svgIcons/SettingSolidIcon";
import UserPlusIcon from "@sellernote/_sds-v2/src/components/svgIcons/UserPlusIcon";
import UserSolidIcon from "@sellernote/_sds-v2/src/components/svgIcons/UserSolidIcon";

import { useTranslation } from "../../../../utils/i18n";

import UserIcon from "../../../UserIcon";
import Logout from "../../Logout";
import Styled from "./index.styles";

export default function AccountInfo({
  accountData,
  isLoadingOfAccountInfo,
  ResponseHandlerOfAccountInfo,
  closeAccountInfo,
  openCreateTeam,
  isMyPage,
}: {
  accountData?: GET_ACCOUNT_INFO_RES;
  isLoadingOfAccountInfo: boolean;
  ResponseHandlerOfAccountInfo: JSX.Element;
  closeAccountInfo: () => void;
  openCreateTeam: () => void;
  isMyPage?: boolean;
}) {
  const queryClient = useQueryClient();

  const { t } = useTranslation(["common-new"]);

  const { isMobile } = useCheckIsMobile();

  const { located: accountInfo, list: accountList } = accountData || {};

  const paging = useClientSidePaging(5, accountList ?? []);

  const {
    mutate: switchAccount,
    ResponseHandler: ResponseHandlerOfSwitchAccount,
  } = TEAM_QUERY.useSwitchAccount({
    onSuccess: () => {
      queryClient.invalidateQueries(USER_QUERY_KEY_GEN.getUserInfo());
    },
  });

  const handleAccountSwitch = (accountId: number) => {
    switchAccount({ pathParams: { teamId: accountId } });
  };

  const getAccountTypeToString = (isPrivate: boolean) => {
    return isPrivate
      ? t("common-new:마이페이지_헤더_계정정보_개인계정")
      : t("common-new:마이페이지_헤더_계정정보_팀계정");
  };

  const companyOrTeamName = accountInfo?.isPrivate
    ? accountInfo?.company
    : accountInfo?.name;

  if (isLoadingOfAccountInfo || !accountInfo || !accountList)
    return (
      <Loading
        active={isLoadingOfAccountInfo || !accountInfo || !accountList}
      />
    );

  return (
    <Styled.container>
      <Styled.accountInfoContainer>
        <Styled.loggedInAccountInfo>
          <UserIcon
            id={accountInfo.id}
            name={companyOrTeamName}
            size={isMobile ? 56 : 80}
            fontStyle={isMobile ? "Head6" : "Head3"}
          />

          <Styled.accountName>{companyOrTeamName}</Styled.accountName>

          <Styled.accountDetail>
            {getAccountTypeToString(accountInfo.isPrivate)}
            {!accountInfo.isPrivate &&
              `(${t("common-new:마이페이지_헤더_계정정보_마스터")}:${
                accountInfo.masterName
              })`}
          </Styled.accountDetail>

          {isMyPage ? (
            <Button
              theme="tertiary"
              borderType="outlined"
              size="normal"
              label={t("common-new:마이페이지_헤더_사용자정보_버튼")}
              width="100%"
              iconInfo={{
                Icon: SettingSolidIcon,
                position: "left",
              }}
              handleClick={() => Router.push("/mypage/member")}
            />
          ) : (
            <Button
              theme="secondary"
              borderType="outlined"
              size="normal"
              label={t("common-new:마이페이지_헤더_마이페이지_버튼")}
              width="100%"
              iconInfo={{
                Icon: UserSolidIcon,
                position: "left",
              }}
              handleClick={() => {
                if (getLatestBidType() === "export") {
                  Router.push("/mypage/export");
                  return;
                }

                Router.push("/mypage");
              }}
            />
          )}
        </Styled.loggedInAccountInfo>

        <Styled.accountList>
          {paging.pageData.map(
            ({ id, masterName, name, company, isPrivate }) => (
              <Styled.accountItem
                key={`account-item-${id}`}
                onClick={() => handleAccountSwitch(id)}
              >
                <UserIcon
                  id={id}
                  name={isPrivate ? company : name}
                  size={48}
                  fontStyle="SubHead2"
                />

                <div className="account-info-container">
                  <div className="account-detail-container">
                    <span className="account-type">
                      {getAccountTypeToString(isPrivate)}
                    </span>

                    {!isPrivate && (
                      <span className="account-master-info">
                        ({t("common-new:마이페이지_헤더_계정정보_마스터")}:
                        {masterName})
                      </span>
                    )}
                  </div>

                  <span className="account-name">
                    {isPrivate ? company : name}
                  </span>
                </div>
              </Styled.accountItem>
            )
          )}
        </Styled.accountList>

        <Styled.paginationWrapper>
          <Pagination
            pageSize={paging.pageSize}
            currentPage={paging.pageNum}
            handleClickPage={(page) => paging.setPageNum(page)}
          />
        </Styled.paginationWrapper>

        {ResponseHandlerOfAccountInfo}
        {ResponseHandlerOfSwitchAccount}

        {!isMobile && (
          <Styled.accountButtonContainer>
            {/* 기존 normal 버튼의 기본 width, padding 이 달라 커스텀 개별설정 */}
            <Logout buttonInfo={{ size: "normal", width: "76px" }} />

            <Button
              theme="tertiary"
              borderType="outlined"
              size="normal"
              width="100%"
              label={t("common-new:마이페이지_헤더_팀생성_버튼")}
              iconInfo={{
                Icon: UserPlusIcon,
                position: "left",
              }}
              handleClick={() => {
                openCreateTeam();
                closeAccountInfo();
              }}
            />
          </Styled.accountButtonContainer>
        )}
      </Styled.accountInfoContainer>

      <Styled.arrowBlock isMyPage={Boolean(isMyPage)} />
    </Styled.container>
  );
}
