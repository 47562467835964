import { useRecoilValue, useSetRecoilState } from "recoil";

import { APP_REGION, PRODUCTION_URL } from "@sellernote/_shared/src/constants";
import AUTH_QUERY from "@sellernote/_shared/src/queries/forwarding/AUTH_QUERY";
import { clearLocalStorageForForwardingShipdaWeb } from "@sellernote/_shared/src/services/browserStorage";
import { FORWARDING_AUTH_ATOMS } from "@sellernote/_shared/src/states/forwarding/auth";
import { SellernoteAppRegion } from "@sellernote/_shared/src/types/common/common";
import Modal from "@sellernote/_sds-v2/src/components/Modal";
import ExclamationTriangleIcon from "@sellernote/_sds-v2/src/components/svgIcons/ExclamationTriangleIcon";

import {
  INVALID_KR_REGION_LOGIN_NOTICE_FOR_SG_USER,
  INVALID_SG_REGION_LOGIN_NOTICE_FOR_KR_USER,
  REDIRECT_TO_KR_BUTTON_LABEL,
  REDIRECT_TO_SG_BUTTON_LABEL,
} from "./INVALID_REGION_NOTICE";

/**
 * 유저가 가입한 Region과 접근한 서비스의 Region이 다를 경우, 서비스 이용을 제한하는 hook
 */
export default function useCheckValidRegionWithModal({
  region,
}: {
  region?: SellernoteAppRegion;
}) {
  const loggedIn = useRecoilValue(FORWARDING_AUTH_ATOMS.USER_LOGGED_IN);

  const setLoggedIn = useSetRecoilState(FORWARDING_AUTH_ATOMS.USER_LOGGED_IN);

  const { data: isUserInProperRegion } = AUTH_QUERY.useCheckValidRegion({
    enabled: Boolean(loggedIn),
  });

  const getUrlByUserRegion = (region: SellernoteAppRegion): string => {
    switch (region) {
      case "KR":
        return PRODUCTION_URL["shipda-kr"];
      case "SG":
        return PRODUCTION_URL["shipda-sg"];
      default:
        return "";
    }
  };

  /**
   * 가입한 Region과 다른 Region의 서비스 사용시 스토리지 초기화 및 로그아웃
   * 로그아웃 이후는 가입한 지역의 운영 서버로 리다이렉트
   */
  const handleLogoutSubmit = () => {
    if (!region) return;

    setLoggedIn(false);
    clearLocalStorageForForwardingShipdaWeb();
    window.sessionStorage.clear();
    window.location.replace(getUrlByUserRegion(region));
  };

  /**
   * 문구 관리 스프레드 시트 회원가입/로그인 탭이 현재 다른 브랜치(형준님)에서 수정되고 있어, 메인에 반영 불가능
   * 해당 작업이 메인에 머지되기 전까지 문구를 상수에 담아 관리
   * FIXME: 스프레드 시트로 관리
   */
  const InvalidRegionModal = (
    <Modal
      active={(loggedIn && isUserInProperRegion === false) || false}
      uiType="iconAndBody"
      Icon={ExclamationTriangleIcon}
      body={
        APP_REGION === "KR"
          ? INVALID_KR_REGION_LOGIN_NOTICE_FOR_SG_USER
          : INVALID_SG_REGION_LOGIN_NOTICE_FOR_KR_USER
      }
      actionPositive={{
        label:
          APP_REGION === "KR"
            ? REDIRECT_TO_SG_BUTTON_LABEL
            : REDIRECT_TO_KR_BUTTON_LABEL,
        handleClick: handleLogoutSubmit,
      }}
    />
  );

  return { InvalidRegionModal };
}
