const INVALID_SG_REGION_LOGIN_NOTICE_FOR_KR_USER = (
  <>
    Korea users cannot use the service in Singapore Region.
    <br />
    Please navigate to the Korea.
  </>
);

const INVALID_KR_REGION_LOGIN_NOTICE_FOR_SG_USER = (
  <>
    싱가포르 사용자는 한국에서 서비스를 이용할 수 없습니다.
    <br />
    싱가포르 서비스로 접속해 주세요.
  </>
);

const REDIRECT_TO_KR_BUTTON_LABEL = "Go to the Korea Region";

const REDIRECT_TO_SG_BUTTON_LABEL = "싱가포르 서비스로 이동하기";

export {
  INVALID_SG_REGION_LOGIN_NOTICE_FOR_KR_USER,
  INVALID_KR_REGION_LOGIN_NOTICE_FOR_SG_USER,
  REDIRECT_TO_KR_BUTTON_LABEL,
  REDIRECT_TO_SG_BUTTON_LABEL,
};
