import React from "react";
import Link from "next/link";
import { useRouter } from "next/router";

import ExpandAccordionClosedIcon from "@sellernote/_sds-v2/src/components/svgIcons/ExpandAccordionClosedIcon";
import ExpandAccordionOpenIcon from "@sellernote/_sds-v2/src/components/svgIcons/ExpandAccordionOpenIcon";
import { COLOR } from "@sellernote/_sds-v2/src/styles/colors";

import { Submenu } from "../../useMenuList";
import Styled from "./index.styles";

export default function MenuItem({
  label,
  submenuList,
  path,
  selected,
  onSelect,
  closeDrawer,
}: {
  label: React.ReactNode;
  submenuList?: Submenu[];
  path?: string;
  selected: boolean;
  onSelect: () => void;
  closeDrawer: () => void;
}) {
  const router = useRouter();

  return (
    <Styled.mobileMenu onClick={onSelect}>
      {path ? (
        <div className={`link-label label`}>
          <Link href={path}>
            <a target="_blank">{label}</a>
          </Link>
        </div>
      ) : (
        <div className={`${selected ? "active" : ""} label`}>
          {label}
          {selected ? (
            <ExpandAccordionOpenIcon
              width={16}
              height={16}
              color={COLOR.grayScale_800}
              className="icon"
            />
          ) : (
            <ExpandAccordionClosedIcon
              width={16}
              height={16}
              color={COLOR.grayScale_800}
              className="icon"
            />
          )}
        </div>
      )}

      {submenuList && (
        <ul className={`${selected ? "active" : ""}`}>
          {submenuList.map((v, i) => {
            return (
              <Styled.mobileSubmenu
                key={i}
                onClick={(e) => {
                  // 아래 a link와 중복기능이지만, subLabel과 뱃지를 클릭했을때도 이동해야 하므로 추가함
                  e.stopPropagation();
                  closeDrawer();

                  // company#about 처럼 hash 를 router.push 하는 경우에 Cancel Rendering Route Error 가 있어서, link 이동을 완료한 이후에 hash로 이동하도록 처리.
                  router.push(v.path).then(() => {
                    if (!v.pathHash) return;

                    router.push(v.pathHash);
                  });
                }}
              >
                <div className="label-container">
                  <Link href={v.path}>
                    <a>
                      <span className="label">{v.label}</span>
                    </a>
                  </Link>

                  <div className="badge-container">
                    {v.badges?.map((badge, i) => (
                      <div className="badge" key={i}>
                        {badge}
                      </div>
                    ))}
                  </div>
                </div>

                <span className="sub-label">{v.subLabel}</span>
              </Styled.mobileSubmenu>
            );
          })}
        </ul>
      )}
    </Styled.mobileMenu>
  );
}
